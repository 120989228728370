import { API_SERVICE_URLS } from '@flowardco/flib-helpers';
import { useFetch } from './useFetch';
import type { AxiosInstance } from 'axios';

export interface APIClientsHook {
  kongCatalogClient: AxiosInstance;
  kongOrderV2Client: AxiosInstance;
}

export const useApiClients = (): APIClientsHook => {
  const kongCatalogClient = useFetch(API_SERVICE_URLS.KONG_CATALOG);
  const kongOrderV2Client = useFetch(API_SERVICE_URLS.KONG_ORDERV2);
  return {
    kongCatalogClient,
    kongOrderV2Client,
  };
};
