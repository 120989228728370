import { Suspense, useState } from 'react';
import { Await, useLoaderData, useParams } from 'react-router-dom';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import type { RecipientAddressModel } from '@flowardco/flib-models';
import { PageLayout } from '@/components/PageLayout';
import { NoResult } from '@flowardco/fui-no-result';
import { DownloadApp } from '@flowardco/fui-download-app';
import CallCenterDrawer from '@/components/AddressPageUI/CallCenterDrawer';
import { AddressPageSkeleton } from '@/components/AddressPageUI/AddressPageSkeleton';
import { PageError } from '@/components/PageError';

const AddressNotConfirmedPage = () => {
  const { orderData } = useLoaderData() as { orderData: RecipientAddressModel };
  const [showCallCenter, setShowCallCenter] = useState<boolean>(false);
  const { lang = 'en' } = useParams();
  const { __T } = useLocalTranslator();
  const onCustomerSupportClick = () => {
    setShowCallCenter((prevState) => !prevState);
  };

  return (
    <PageLayout
      showCustomerSupport
      onCustomerSupportClick={onCustomerSupportClick}
    >
      <Suspense fallback={<AddressPageSkeleton />}>
        <Await resolve={orderData}>
          {(resolvedOrderData) => {
            if (!resolvedOrderData?.orderInfo?.id) {
              return <PageError message={'Order Not Found'} />;
            }
            return (
              <>
                {showCallCenter && (
                  <CallCenterDrawer
                    opsId={resolvedOrderData?.orderInfo?.ops?.id}
                    lang={lang}
                    onClose={() => {
                      setShowCallCenter(false);
                    }}
                    __T={__T}
                  />
                )}
                <NoResult
                  className='fui-h-full fui-flex-1 !fui-justify-between fui-px-4'
                  icon={null}
                  button={null}
                  text={__T('Order is Not confirmed yet')}
                />

                <div className='-fui-mx-4 -fui-mb-4 fui-px-4 fui-pt-9'>
                  <DownloadApp
                    header={__T('Discover the new Floward app')}
                    subHeader={__T(
                      'Use "WELCOME" to get a 10% discount on your first order'
                    )}
                    className='fui-bg-gray-75'
                    imageHolderClass='fui-bg-white'
                  />
                </div>
              </>
            );
          }}
        </Await>
      </Suspense>
    </PageLayout>
  );
};

export default AddressNotConfirmedPage;
