'use client';
import {
  localStore,
  STORAGE_KEYS,
  CURRENCY_CODE,
  sessionStore,
} from '@flowardco/flib-helpers';
import type {
  ProductModel,
  UserModel,
  Product,
  ProductPageTypeEnum,
  CartListItem,
  CartItemsInfo,
} from '@flowardco/flib-models';
import { ProductType } from '@flowardco/flib-models';
import { convertDateToYMD } from '@flowardco/flib-util';
import { useApp } from './useApp';
import { APP_CONFIGS } from '@flowardco/flib-helpers';
import { formatPDPUrl } from '../helpers/formatProductData';
import type { Result } from '@growthbook/growthbook';
import { SET_BRAZE_LOADED } from '@flowardco/app-shared/context';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    analytics: any;
  }
}

export interface Filter {
  type: string;
  value: string;
}

export enum SortType {
  Recommended,
  PriceLTH,
  PriceHTL,
  New,
}
export type CustomizButtonEventProps = {
  source?: string;
  sourceId?: number;
  giftsetProductId?: number;
};
type GiftAgainProps = {
  orderId: number;
  newOrderId: number;
  pageSource: string;
  isEligible: boolean;
};
type OosDataProps = {
  orderId: number;
  pageSource: string;
  isEligible: boolean;
  product_ids: string[];
  newOrderId?: number;
};
export interface SegmentHook {
  initalSegmentLoad: (apiKey: string, writeKey: string) => void;
  track: (eventName: string, trackingData: any) => void;
  trackProductSearch: (query: string, landingSource?: string) => void;
  trackSearchClicked: () => void;
  trackABExperimentsView: (
    result: Result<any>,
    orderId?: number,
    additionalProps?: any
  ) => void;
  trackABExperimentsEvents: (eventName: string, props?: any) => void;
  trackProductAdded: (
    orderDetailsId: number,
    product: ProductModel,
    position?: number,
    pageType?: ProductPageTypeEnum
  ) => void;
  trackProductESAdded: (
    orderDetailsId: number,
    product: Product,
    position?: number,
    categoryName?: string,
    pageType?: ProductPageTypeEnum,
    source?: string,
    sourceId?: number,
    enablePreOrder?: boolean,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string,
    sectionPosition?: string,
    componentPosition?: string,
    queryId?: string
  ) => void;
  trackBothProductsESAdded: (
    orderDetailsId: number,
    mainProduct: Product,
    recommendedProduct: Product,
    categoryName?: string,
    pageType?: ProductPageTypeEnum,
    source?: string,
    sourceId?: number,
    enablePreOrder?: boolean
  ) => void;
  trackProductRemoved: (
    orderDetailsId: number,
    product: any,
    position: number
  ) => void;
  trackCartViewed: (
    orderDetailsId: number,
    dataList: any[],
    isNewFlow?: boolean
  ) => void;
  trackCheckoutStarted: (
    cartListItems: CartItemsInfo | null,
    cartData: any
  ) => void;
  trackPromotionClicked: (bannerData: BannerModel) => void;
  trackUpdateAccount: (email: string, traits: any) => void;
  identifyUpdateAccount: (uuid: string, email: string, traits: any) => void;
  identifyChangeLang: (uuid: string, lang: string) => void;
  trackUserLogOut: (email: string) => void;
  trackSegmentMixPanelSessionStart: () => void;
  trackHomePageViewed: () => void;
  trackButtonClicked: (
    button_name: string,
    section: string,
    sourceId?: number,
    page_source?: string
  ) => void;
  trackChangeOpsCenter: (sentData: any) => void;
  trackChangeLanguage: (data: any) => void;
  resetSegment: () => void;
  trackProductWishlistAddedToCart: (
    orderDetailsId: number,
    product: Product,
    position: number
  ) => void;
  trackProductClicked: (
    product: ProductModel,
    position: number,
    actionField: string,
    pageType: string
  ) => void;
  trackProductESClicked: (
    product: Product,
    position: number,
    actionField: string,
    pageType: string,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string,
    queryId?: string
  ) => void;
  trackProductViewed: (product: ProductModel, position: number) => void;
  trackProductModal: (product: ProductModel, status: boolean) => void;
  trackMenuItemClicked: (landingSource: string) => void;
  trackProductESViewed: (
    product: Product,
    position: number,
    pageSource: string,
    source?: string,
    sourceId?: number,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string,
    sectionPosition?: string,
    componentPosition?: string,
    abTestResult?: Result<any> | undefined,
    abTestResultPDP?: Result<any> | undefined,
    queryId?: string
  ) => void;
  trackQuickFIlterClicked: (
    filterName: string,
    filterValue: string,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string
  ) => void;
  trackCouponEntered: (orderDetailsId: number, coupon: string) => void;
  trackCouponApplied: (
    orderDetailsId: number,
    coupon: string,
    discount: number
  ) => void;
  trackCouponDenied: (
    orderDetailsId: number,
    coupon: string,
    reason: string
  ) => void;
  trackCheckoutStepCompleted: (
    dataList: CartListItem[],
    step: number,
    orderDetailsId: number,
    paymentMethod: string,
    abTestResult?: Result<any> | undefined,
    newAddressSlotFlowEnabled?: boolean
  ) => void;
  trackCheckoutStepViewed: (
    dataList: CartListItem[],
    step: number,
    orderDetailsId: number,
    paymentMethod: string,
    newAddressSlotFlowEnabled: boolean
  ) => void;
  trackMOVNotReached: (
    orderId: number,
    subTotal: number,
    mov: number,
    currency: string
  ) => void;
  trackPaymentInfoEntered: (
    orderDetailsId: number,
    payment_method: string,
    step?: number
  ) => void;
  trackProductWishlistAdded: (product: Product, position?: number) => void;
  trackProductWishlistRemoved: (product: Product, position?: number) => void;
  trackCreateAccount: (sentData: UserModel) => void;
  identifyCreateAccount: (sentData: UserModel) => void;
  trackUserSignIn: (user: UserModel) => void;
  trackIdentifySignIn: (user: UserModel) => void;
  trackHelpCenterOpen: (order_number: number) => void;
  trackDeliverySlotSelected: (
    deliveryTimeName: string,
    slot_type?: string
  ) => void;
  trackProductListViewed: (
    dataList: Product[],
    categoryName: string,
    pageSource?: string,
    query?: string,
    source?: string,
    sourceId?: number,
    collectionId?: string,
    collectionKey?: string,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string,
    sectionPosition?: string,
    componentPosition?: string
  ) => void;
  trackProductListFiltered: (
    dataList: Product[],
    filterKeys: any,
    categoryName: string
  ) => void;
  trackPage: (pageCategory: string, pageName: string, otherProps?: any) => void;
  trackOrderCompleted: (
    orderData: any,
    email: string,
    isNewCustomer?: boolean
  ) => void;
  trackUnknownAddress: (
    eventName: string,
    page_source: string,
    is_unknown_address: boolean
  ) => void;
  trackAnnonymousChecked: (
    eventName: string,
    page_source: string,
    is_anonymous: boolean
  ) => void;
  trackProductQuantity: (quantityAction: string, data: any) => void;
  trackNoteForTeam: (note: string) => void;
  trackLinkUpload: (link: string) => void;
  trackCountryCode: (defaultCode: string, newCode: string) => void;
  trackSavedAddressClick: (props: any) => void;
  trackManualAddress: (props?: any) => void;
  trackUnavailableZones: (eventName: string) => void;
  trackAlgoliaAbTesting: (abTestID: string, abTestVariantID: string) => void;
  isSegmentLoaded: () => boolean;
  isSegmentEnabled: () => boolean;
  trackExternalLinkClicked: (sourceValue: string) => void;
  trackViewAllClicked: (landingSource: string, props?: any) => void;
  trackCheckoutAddressAndSlots: (event: string, eventProps: any) => void;
  trackCustomCardAdded: (customCardData: any) => void;
  trackUseMyWallet: (orderId: number, isSelected: boolean) => void;
  trackGiftWrapModalOpened: (
    giftWrapData: any,
    abTestResult?: Result<any> | undefined
  ) => void;
  trackProductVasAdded: (giftWrapData: any) => void;
  trackProductVasRemoved: (giftWrapData: any) => void;
  trackPurchaseWithGiftWrap: (
    orderId: number,
    abTestResult?: Result<any> | undefined
  ) => void;
  trackPurchaseWithCustomCard: (
    orderId: number,
    cardId: number,
    abTestResult?: Result<any> | undefined
  ) => void;
  trackCheckoutError: (
    pageName: string,
    orderId?: number,
    error?: string,
    action?: string
  ) => void;
  trackCustomizeGiftBoxButton: (eventData: CustomizButtonEventProps) => void;
  trackGiftBoxModalSingleSelect: (id: number) => void;
  trackGiftBoxModalGroupeSelect: (ids: number[]) => void;
  trackClickSingleGroup: (giftsetId: number, defaultProductId: number) => void;
  trackGiftAgain: (data: GiftAgainProps) => void;
  trackOnContinueOOSBtn: (data: OosDataProps) => void;
  trackOnStartShoppingOOSBtn: (data: OosDataProps) => void;
  trackHomePageClicks: (data: any) => void;
  trackSubscriptionCompleted: (orderData: any, subscriptionId: string) => void;
  trackSubscriptionTierSelected: (
    subscriptionTierId: number,
    page_source: string
  ) => void;
  trackSubscriptionPaymentInfoEntered: (
    subscription_id: string,
    payment_method: string
  ) => void;
  trackSubscriptionPageLanded: (page_source: string) => void;
  trackSubscriptionStarted: (subsData: any) => void;
  trackSubscriptionEditPaymentDetails: (
    id: string,
    orderId: number | undefined | null,
    paymentMethod: string
  ) => void;
  trackViewMySubscription: () => void;
  trackSubscriptionClicked: (id: string) => void;
  trackAddonsPopupViewed: () => void;
  trackAddonsAddedToCart: (addon: any, source: string) => void;
  trackIncreaseAddonsQTY: (addon: any, qty: number, source: string) => void;
  trackDecreaseAddonsQTY: (addon: any, qty: number, source: string) => void;
  trackAddonCategoryChanged: (
    categoryName: string,
    categoryRank: number
  ) => void;
}

export interface BannerModel {
  promotion_id: string;
  creative: string;
  name: string;
  position: string;
  extra_data: UrlModel;
}
export interface UrlModel {
  url: string;
}

export enum CheckoutStep {
  Message = 1,
  Address = 2,
  Date = 3,
  Payment = 4,
  Reciept = 5,
}
export enum CheckoutSteps {
  Message = 1,
  DeliveryDetails = 2,
  Payment = 3,
  Reciept = 4,
}

export enum CheckoutSubscriptionStep {
  Address = 1,
  Subscription = 2,
  Payment = 3,
}

export const useSegment = (): SegmentHook => {
  const { appState, appDispatch } = useApp();
  let customerState: any = {};
  const data = localStore.get(STORAGE_KEYS.USER_ID);
  const [utmParams, setUTMParams] = useState<any>({});

  useEffect(() => {
    const params = sessionStore.getUTMParams();
    if (params) {
      setUTMParams(params);
    }
  }, []);

  if (data) {
    const userData = JSON.parse(data);
    customerState = {
      phone: `${userData?.country?.code}${userData?.phone}`,
      email: userData?.email,
    };
  }

  const isSegmentEnabled = (): boolean => {
    return APP_CONFIGS.tracking.segment.enabled;
  };

  const isSegmentLoaded = (): boolean => {
    return (
      typeof window !== 'undefined' &&
      typeof window?.['analytics'] !== 'undefined'
    );
  };

  const initalSegmentLoad = (apiKey: string, writeKey: string) => {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) {
        // eslint-disable-next-line no-console
        console?.error('Segment snippet included twice.');
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        analytics.factory = function (e: any) {
          return function () {
            // eslint-disable-next-line prefer-rest-params
            const t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (let e = 0; e < analytics.methods.length; e++) {
          const key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (_key: string, e: any) {
          analytics._loadOptions = e;
        };
        analytics._writeKey = writeKey;
        analytics.SNIPPET_VERSION = '4.15.3';
        analytics.load(apiKey, { obfuscate: true }); // api key
        //analytics.page();
        analytics.ready(function () {
          appDispatch({ type: SET_BRAZE_LOADED, payload: true });
        });
      }
  };

  const track = (eventName: string, trackingData: any): void => {
    if (isSegmentEnabled() && isSegmentLoaded()) {
      window['analytics'].track(eventName, {
        ops_id: appState.opsId || 1,
        is_authenticated: localStore.isLoggedIn(),
        language: appState.lang,
        store_channel: 'Web',
        ...trackingData,
        ...(utmParams || {}),
      });
    }
  };

  const trackPage = (
    pageCategory: string,
    pageName: string,
    otherProps?: any
  ): void => {
    if (isSegmentEnabled() && isSegmentLoaded()) {
      window['analytics'].page(pageCategory, pageName, {
        ops_id: appState.opsId || 1,
        is_authenticated: localStore.isLoggedIn(),
        language: appState.lang,
        store_channel: 'Web',
        page_url: window.location.href,
        ...(otherProps || {}),
      });
    }
  };

  const identify = (userId: string, identifyData: any, lang?: string): void => {
    if (isSegmentEnabled() && isSegmentLoaded()) {
      window['analytics'].identify(userId, {
        ops_id: appState.opsId || 1,
        is_authenticated: localStore.isLoggedIn(),
        language: lang || appState.lang,
        store_channel: 'Web',
        ...identifyData,
      });
    }
  };

  const resetSegment = (): void => {
    if (appState?.segmentLoaded && isSegmentEnabled()) {
      window['analytics'].reset();
    }
  };

  // Core Event Tracking

  // Fire this event when a visitor clicks a product.
  const trackProductClicked = (
    product: ProductModel,
    position: number = 0,
    actionField: string,
    pageType: string
  ): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        ...mapProduct(position, product, getUSDDollar()),
        phone: customerState?.phone,
        email: customerState?.email,
        extra_data: {
          ecommerce: {
            currency_code: CURRENCY_CODE,
            actionField: { list: actionField },
            page_type: pageType,
          },
        },
      };
      track('Product Clicked', sentData);
    }
  };

  const trackProductESClicked = (
    product: Product,
    position: number = 0,
    actionField: string,
    pageType: string,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string,
    queryId?: string
  ): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        ...mapProductES(position, product, getUSDDollar()),
        phone: customerState?.phone,
        email: customerState?.email,
        extra_data: {
          ecommerce: {
            currency_code: CURRENCY_CODE,
            actionField: { list: actionField },
            page_type: pageType,
          },
        },
        landing_source: landingSource,
        source_value: sourceValue,
        source_name: sourceName,
        queryID: queryId && queryId !== 'undefined' ? queryId : '',
        indexName: appState?.globalData?.indexName || 'product',
      };
      track('Product Clicked', sentData);
    }
  };
  // Fire this event when a visitor clicks a product.
  const trackProductViewed = (
    product: ProductModel,
    position: number = 0
  ): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        ...mapProduct(position, product, getUSDDollar()),
        phone: customerState?.phone,
        email: customerState?.email,
        value: product.Price * getUSDDollar(),
        currency: CURRENCY_CODE,
        extra_data: {
          //  GTM
          ecommerce: {
            currency_code: CURRENCY_CODE,
          },
          // Criteo
          page_type: 'Productpage',
          hashed_email: getHashedemail(),
        },
      };
      track('Product Viewed', sentData);
    }
  };
  const trackProductModal = (product: any, status = false): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        product,
        phone: customerState?.phone,
        email: customerState?.email,
        value: product.Price * getUSDDollar(),
        currency: CURRENCY_CODE,
        extra_data: {
          //  GTM
          ecommerce: {
            currency_code: CURRENCY_CODE,
          },
          // Criteo
          page_type: 'Productpage',
          hashed_email: getHashedemail(),
        },
      };
      track(`Frequently Bought Product ${status ? 'View' : 'Close'}`, sentData);
    }
  };
  const trackProductESViewed = (
    product: Product,
    position: number = 0,
    pageSource: string,
    source?: string,
    sourceId?: number,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string,
    sectionPosition?: string,
    componentPosition?: string,
    abTestResult?: Result<any> | undefined,
    abTestResultPDP?: Result<any> | undefined,
    queryId?: string
  ): void => {
    if (appState?.segmentLoaded) {
      let page_source = pageSource;
      if (pageSource) {
        page_source =
          typeof pageSource === 'object' && pageSource?.[0]
            ? pageSource?.[0]
            : pageSource;
      }
      let experiment_data: any;
      if (abTestResult?.featureId) {
        experiment_data = {
          experiment_id: abTestResult?.featureId,
          variant_name: abTestResult?.key,
          variant_value: abTestResult?.value
            ? `${abTestResult?.value}`
            : undefined,
        };
      }
      let experiment_pdp_data: any;
      if (abTestResultPDP?.featureId) {
        experiment_pdp_data = {
          experiment_id: abTestResultPDP?.featureId,
          variant_name: abTestResultPDP?.key,
          variant_value: abTestResultPDP?.value
            ? `${abTestResultPDP?.value}`
            : undefined,
        };
      }
      const sentData = {
        ...mapProductES(position, product, getUSDDollar()),
        phone: customerState?.phone,
        email: customerState?.email,
        value: (product?.productAvailability?.price * getUSDDollar())?.toFixed(
          3
        ),
        gift_set_product_type: product?.customizable
          ? ProductType?.[product?.type]
          : null,
        gift_set_id: product?.customizable ? product?.id : null,
        currency: CURRENCY_CODE,
        extra_data: {
          //  GTM
          ecommerce: {
            currency_code: CURRENCY_CODE,
          },
          // Criteo
          page_type: 'Productpage',
          hashed_email: getHashedemail(),
        },
        page_source,
        source,
        source_id: sourceId,
        landing_source: landingSource,
        source_value: sourceValue,
        source_name: sourceName,
        component_position: componentPosition,
        section_position: sectionPosition,
        experiment_data,
        experiment_pdp_data,
        queryID: queryId && queryId !== 'undefined' ? queryId : '',
        indexName: appState?.globalData?.indexName || 'product',
      };
      track('Product Viewed', sentData);
    }
  };
  // Fire this event when a visitor adds a product to their shopping cart.
  const trackProductAdded = (
    orderDetailsId: number,
    product: ProductModel,
    position: number = 0,
    pageType?: ProductPageTypeEnum
  ) => {
    if (appState?.segmentLoaded) {
      const totalPrice = getTotalValue(product, 1);
      const sentData = {
        ...mapProduct(position, product, getUSDDollar(), product.qty),
        phone: customerState?.phone,
        email: customerState?.email,
        cart_id: String(orderDetailsId),
        extra_data: {
          //  GTM
          ecommerce: {
            currency_code: CURRENCY_CODE,
            total_value: totalPrice,
            page_type_gtm: '',
          },
          // Criteo
          page_type_criteo: 'Basketpage',
          hashed_email: getHashedemail(),
        },
        page_source: pageType || '',
      };
      track('Product Added', sentData);
    }
  };
  // Fire this event when a visitor adds a product to their shopping cart.

  const trackProductESAdded = (
    orderDetailsId: number,
    product: Product,
    position: number = 0,
    categoryName?: string,
    pageType?: ProductPageTypeEnum,
    source?: string,
    sourceId?: number,
    enablePreOrder?: boolean,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string,
    sectionPosition?: string,
    componentPosition?: string,
    queryId?: string
  ) => {
    if (appState?.segmentLoaded) {
      const totalPrice = getTotalValueES(product, 1)?.toFixed(3);
      const sentData = {
        ...mapProductES(
          position,
          product,
          getUSDDollar(),
          undefined,
          categoryName
        ),
        phone: customerState?.phone,
        email: customerState?.email,
        customizable: product?.customizable,
        giftset_product_id: product?.customizable ? product?.id : null,
        gift_set_product_type: product?.customizable
          ? ProductType?.[product?.type]
          : null,
        source,
        source_id: sourceId,
        pre_order: enablePreOrder || false,
        cart_id: String(orderDetailsId),
        extra_data: {
          //  GTM
          ecommerce: {
            currency_code: CURRENCY_CODE,
            total_value: totalPrice,
            page_type_gtm: '',
          },
          // Criteo
          page_type_criteo: 'Basketpage',
          hashed_email: getHashedemail(),
        },
        page_source: pageType || '',
        landing_source: landingSource,
        source_value: sourceValue,
        source_name: sourceName,
        component_position: componentPosition,
        section_position: sectionPosition,
        queryID: queryId && queryId !== 'undefined' ? queryId : '',
        indexName: appState?.globalData?.indexName || 'product',
      };
      track('Product Added', sentData);
    }
  };
  // Track add both to cart for recommendation products
  const trackBothProductsESAdded = (
    orderDetailsId: number,
    mainProduct: Product,
    recommendedProduct: Product,
    categoryName?: string,
    pageType?: ProductPageTypeEnum,
    source?: string,
    sourceId?: number,
    enablePreOrder?: boolean
  ) => {
    if (appState?.segmentLoaded) {
      const USD = getUSDDollar();
      const totalPrice =
        getTotalValueES(mainProduct, 1) +
        getTotalValueES(recommendedProduct, 1);

      const sentData = {
        category: categoryName ? categoryName : '',
        phone: customerState?.phone,
        email: customerState?.email,
        source,
        source_id: sourceId,
        pre_order: enablePreOrder || false,
        cart_id: String(orderDetailsId),
        extra_data: {
          //  GTM
          ecommerce: {
            currency_code: CURRENCY_CODE,
            total_value: totalPrice,
            page_type_gtm: '',
          },
          // Criteo
          page_type_criteo: 'Basketpage',
          hashed_email: getHashedemail(),
        },
        page_source: pageType || '',
        main_product_id: mainProduct.id,
        recommended_product_id: mainProduct.id,
        main_price: mainProduct?.productAvailability?.price * USD,
        recommended_price: recommendedProduct?.productAvailability?.price * USD,
      };
      track('Add both to cart', sentData);
    }
  };
  // Fire this event when a visitor removes a product from their shopping cart.
  const trackProductRemoved = (
    orderDetailsId: number,
    product: any,
    position: number = 0
  ) => {
    if (appState?.segmentLoaded) {
      const totalPrice = getTotalValueFromCart(product, product.qty);
      const sentData = {
        ...mapCartProduct(position, product, getUSDDollar()),
        phone: customerState?.phone,
        email: customerState?.email,
        cart_id: String(orderDetailsId),
        quantity: product.qty,
        brand: '',
        variant: '',
        coupon: '',
        extra_data: {
          //  GTM
          ecommerce: {
            currenc_code: CURRENCY_CODE,
            total_value: totalPrice,
            page_type: 'cart',
          },
        },
      };
      track('Product Removed', sentData);
    }
  };

  const trackMenuItemClicked = (landingSource: string) => {
    track('menu_bar_clicked', { landingSource });
  };

  const trackExternalLinkClicked = (sourceValue: string) => {
    if (appState?.segmentLoaded) {
      track('deeplink_clicked', {
        phone: customerState?.phone,
        email: customerState?.email,
        landing_source: 'external_link',
        source_value: sourceValue,
      });
    }
  };

  const trackViewAllClicked = (landingSource: string, additionalProps = {}) => {
    if (appState?.segmentLoaded) {
      track('view_all_clicked', {
        phone: customerState?.phone,
        email: customerState?.email,
        landing_source: landingSource,
        ...(additionalProps || {}),
      });
    }
  };

  // Fire this event when a visitor views a shopping cart.
  const trackCartViewed = (
    orderDetailsId: number,
    dataList: any[],
    isNewFlow = false
  ): void => {
    if (appState?.segmentLoaded) {
      const mapedData =
        dataList?.map((product, i) =>
          mapCartProduct(i, product, getUSDDollar())
        ) || [];

      const sentData = {
        products: mapedData,
        phone: customerState?.phone,
        email: customerState?.email,
        cart_id: String(orderDetailsId),
        is_new_card_flow: isNewFlow,
      };
      track('Cart Viewed', sentData);
    }
  };

  // Coupon Event Tracking

  // Fire this event whenever a coupon is entered either on a cart or on an order/transaction.
  const trackCouponEntered = (orderDetailsId: number, coupon: string): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        order_id: String(orderDetailsId),
        cart_id: String(orderDetailsId),
        coupon_id: coupon,
        phone: customerState?.phone,
        email: customerState?.email,
      };

      track('Coupon Entered', sentData);
    }
  };

  // Fire this event whenever a coupon is successfully applied to either a cart or an order/transaction.
  const trackCouponApplied = (
    orderDetailsId: number,
    coupon: string,
    discount: number
  ): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        order_id: String(orderDetailsId),
        cart_id: String(orderDetailsId),
        coupon_id: coupon,
        coupon_name: coupon,
        discount: discount ? discount * getUSDDollar() : 0,
        phone: customerState?.phone,
        email: customerState?.email,
      };

      track('Coupon Applied', sentData);
    }
  };

  // Fire this event whenever a coupon is successfully applied to either a cart or an order/transaction.
  const trackCouponDenied = (
    orderDetailsId: number,
    coupon: string,
    reason: string
  ): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        order_id: String(orderDetailsId),
        cart_id: String(orderDetailsId),
        coupon_name: coupon,
        coupon_id: coupon,
        reason,
        phone: customerState?.phone,
        email: customerState?.email,
      };

      track('Coupon Denied', sentData);
    }
  };
  // Browsing Event Tracking

  // Fire this event when a visitor searches for products.
  const trackProductSearch = (query: string, landingSource?: string): void => {
    track('Products Searched', {
      query,
      landing_source: landingSource,
      source_value: query,
      phone: customerState?.phone,
      email: customerState?.email,
    });
  };

  const trackSearchClicked = (): void => {
    track('Search clicked', {});
  };

  const trackAlgoliaAbTesting = (abTestID: string, abTestVariantID: string) => {
    if (appState?.segmentLoaded) {
      if (abTestID && abTestVariantID) {
        track('Algolia ABTest', {
          userToken: localStore.getUniqIDFromStore(),
          abTestID,
          abTestVariantID,
          phone: customerState?.phone,
          email: customerState?.email,
        });
      }
    }
  };

  //quick filters
  const trackQuickFIlterClicked = (
    filterName: string,
    filterValue: string,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string
  ) => {
    if (appState?.segmentLoaded) {
      if (filterName && filterValue) {
        track('Quick filter clicked', {
          filter_name: filterName,
          filter_value: filterValue,
          landing_source: landingSource,
          source_value: sourceValue,
          source_name: sourceName,
          phone: customerState?.phone,
          email: customerState?.email,
        });
      }
    }
  };

  // Fire this event when a visitor views a product list or category.
  const trackProductListViewed = (
    dataList: Product[],
    categoryName?: string,
    pageSource?: string,
    query?: string,
    source?: string,
    sourceId?: number,
    collectionId?: string,
    collectionKey?: string,
    landingSource?: string,
    sourceValue?: string,
    sourceName?: string,
    sectionPosition?: string,
    componentPosition?: string
  ): void => {
    if (appState?.segmentLoaded) {
      const mapedData: any[] = [];
      const mapedIds: string[] = [];
      let totalPrice = 0;

      let page_source = pageSource;
      if (pageSource) {
        page_source =
          typeof pageSource === 'object' && pageSource?.[0]
            ? pageSource?.[0]
            : pageSource;
      }

      dataList?.forEach((product, i) => {
        totalPrice += getTotalValueES(product);
        mapedData.push(
          mapProductES(i, product, getUSDDollar(), 1, categoryName)
        );
        mapedIds.push(String(product.id));
      });

      const sentData = {
        list_id: collectionId,
        category: categoryName,
        products: mapedData,
        query,
        extra_data: {
          // GTM
          ecommerce: {
            currency_code: CURRENCY_CODE,
            total_value: totalPrice,
          },
          // Criteo
          page_type: 'Listingpage',
          hashed_email: getHashedemail(),
          product_id_list: mapedIds,
        },
        page_source,
        source: source,
        source_id: sourceId,
        collection_id: collectionId,
        collection_key: collectionKey,
        landing_source: landingSource,
        source_value: sourceValue,
        source_name: sourceName,
        component_position: componentPosition,
        section_position: sectionPosition,
        indexName: appState?.globalData?.indexName || 'product',
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Product List Viewed', sentData);
    }
  };

  // Send this event when a visitor filters a product list or category.
  const trackProductListFiltered = (
    dataList: Product[],
    filterKeys: any,
    categoryName: string
  ): void => {
    if (appState?.segmentLoaded) {
      let mapedData: any[] = [];
      let mappedFilters: any[] = [];
      let price: Filter[] = [];
      mapedData = dataList?.map((product, i) => {
        return mapProductES(i, product, getUSDDollar(), 1, categoryName);
      });
      const mappedCategories =
        filterKeys?.categoriesFilter?.map((filter: any) => {
          return mapFilters(filter);
        }) || [];

      const filters =
        filterKeys?.filter?.map((filter: any) => {
          return mapFilters(filter);
        }) || [];

      if (filterKeys.endingPrice) {
        price = [
          {
            type: 'price',
            value: filterKeys.startingPrice + '-' + filterKeys.endingPrice,
          },
        ];
      } else if (filterKeys?.startingPrice && !filterKeys?.endingPrice) {
        price = [
          {
            type: 'price',
            value: `${filterKeys.startingPrice} or above`,
          },
        ];
      }
      mappedFilters = [...mappedCategories, ...filters, ...price];
      const sentData = {
        phone: customerState?.phone,
        email: customerState?.email,
        list_id: categoryName,
        category: categoryName,
        filters: mappedFilters,
        sorts: [mapSorts(filterKeys.sortBy)],
        products: mapedData,
      };

      track('Product List Filtered', sentData);
    }
  };

  // Checkout Event Tracking

  // Fire on Out Of Stock validation error
  const trackCheckoutError = (
    pageName: string,
    orderId?: number,
    error?: string,
    action?: string
  ): void => {
    if (appState?.segmentLoaded) {
      track('Checkout Error', {
        order_id: orderId,
        page_name: pageName,
        phone: customerState?.phone,
        email: customerState?.email,
        error,
        action,
      });
    }
  };

  // Fire this event whenever an order/transaction was started. Fire on the page that the customer lands on after they press the checkout button.
  const trackCheckoutStarted = (
    cartListItems: CartItemsInfo | null,
    cartData: any
  ) => {
    if (appState?.segmentLoaded) {
      let totalPrice = 0;
      const mapedData = cartListItems?.cart?.map((product: any, i: number) => {
        totalPrice += getTotalValueFromCart(product, product.qty);
        return {
          ...mapCartProduct(i, product, getUSDDollar()),
          quantity: 1,
        };
      });

      // Getting discount from paymentDetails for now
      const discount = cartListItems?.paymentDetails?.find(
        (paymentDetail: any) => paymentDetail.id === 2
      );

      const sentData = {
        order_id: String(cartData?.id),
        phone: customerState?.phone,
        email: customerState?.email,
        affiliation: 'Floward Web',
        value: cartListItems?.usdGrandTotal,
        revenue: totalPrice,
        shipping:
          (cartListItems?.deliveryCost || 0) *
          (appState?.currentOpsCenter?.usdExchangeRate || 0),
        tax:
          (cartListItems?.vatAmount || 0) *
          (appState?.currentOpsCenter?.usdExchangeRate || 0),
        discount: discount?.amount
          ? discount.amount * (appState?.currentOpsCenter?.usdExchangeRate || 0)
          : 0,
        coupon: cartListItems?.couponCode,
        currency: CURRENCY_CODE,
        products: mapedData,
      };
      track('Checkout Started', sentData);
    }
  };

  // Fire this event whenever a checkout step is viewed.
  const trackCheckoutStepViewed = (
    dataList: CartListItem[],
    step: number,
    orderDetailsId: number,
    paymentMethod = '',
    newAddressSlotFlowEnabled = false
  ): void => {
    if (appState?.segmentLoaded) {
      let totalPrice = 0;
      const mapedData =
        dataList?.map((product, i) => {
          // NOTE Check if this is supposed to be overwritten by the last products price
          totalPrice = getTotalValueFromCart(product, product?.qty);
          return mapCartProduct(i, product, getUSDDollar());
        }) || [];

      const sentData = {
        checkout_id: String(orderDetailsId),
        phone: customerState?.phone,
        email: customerState?.email,
        step,
        shipping_method: '',
        payment_method: paymentMethod,
        step_name: newAddressSlotFlowEnabled
          ? mapNewCheckoutSteps(step)
          : mapCheckoutSteps(step),
        extra_data: {
          ecommerce: {
            page_type: newAddressSlotFlowEnabled
              ? mapNewCheckoutSteps(step)
              : mapCheckoutSteps(step),
            total_value: totalPrice,
            currency_code: CURRENCY_CODE,
            transaction_id: orderDetailsId,
            checkout: {
              action_field: { step: step, option: paymentMethod },
              products: mapedData,
            },
          },
        },
      };
      track('Checkout Step Viewed', sentData);
    }
  };

  const trackMOVNotReached = (
    orderId: number,
    subTotal: number,
    mov: number,
    currency: string
  ): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        order_id: orderId,
        current_order_value: subTotal,
        current_order_value_currency: currency,
        minimum_order_value: mov,
        minimum_order_value_currency: currency,
      };
      track('Minimum Order Value Not Reached', sentData);
    }
  };

  // Fire this event whenever a checkout step is completed.
  const trackCheckoutStepCompleted = (
    dataList: CartListItem[],
    step: number,
    orderDetailsId: number,
    paymentMethod = '',
    abTestResult: Result<any> | undefined,
    newAddressSlotFlowEnabled = false
  ): void => {
    if (appState.segmentLoaded) {
      let totalPrice = 0;
      const mapedData =
        dataList?.map((product, i) => {
          // NOTE Check if this is supposed to be overwritten by the last products price
          totalPrice = getTotalValueFromCart(product, product.qty);
          return mapCartProduct(i, product, getUSDDollar());
        }) || [];
      let experiment_data: any;
      if (abTestResult?.featureId) {
        experiment_data = {
          experiment_id: abTestResult?.featureId,
          variant_name: abTestResult?.key,
          variant_value: abTestResult?.value
            ? `${abTestResult?.value}`
            : undefined,
        };
      }
      const sentData = {
        checkout_id: String(orderDetailsId),
        phone: customerState?.phone,
        email: customerState?.email,
        step,
        shipping_method: '',
        payment_method: paymentMethod,
        step_name: newAddressSlotFlowEnabled
          ? mapNewCheckoutSteps(step)
          : mapCheckoutSteps(step),
        extra_data: {
          ecommerce: {
            page_type: newAddressSlotFlowEnabled
              ? mapNewCheckoutSteps(step)
              : mapCheckoutSteps(step),
            total_value: totalPrice,
            currency_code: CURRENCY_CODE,
            transaction_id: orderDetailsId,
            checkout: {
              action_field: { step, option: paymentMethod },
              products: mapedData,
            },
          },
        },
        experiment_data,
      };
      track('Checkout Step Completed', sentData);
    }
  };

  // Fire this event whenever payment information has been successfully entered.
  const trackPaymentInfoEntered = (
    orderDetailsId: number,
    payment_method: string,
    step: number = 4
  ): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        checkout_id: String(orderDetailsId),
        order_id: String(orderDetailsId),
        step,
        shipping_method: '',
        payment_method,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Payment Info Entered', sentData);
    }
  };

  const trackOrderCompleted = (
    orderData: any,
    email: string,
    isNewCustomer: boolean | undefined
  ): void => {
    if (appState.segmentLoaded) {
      let totalPrice = 0;
      const mapedData =
        orderData?.cart?.map((product: any, i: number) => {
          totalPrice = getTotalValueFromCart(product, product.qty);
          return mapCartProduct(i, product, getUSDDollar());
        }) || [];
      const discount =
        orderData?.paymentDetails?.find(
          (paymentDetail: { id: number }) => paymentDetail.id === 2
        ) || 0;

      const sentData = {
        checkout_id: String(orderData?.id),
        phone: customerState?.phone,
        order_id: String(orderData?.id),
        affiliation: 'Floward Web',
        total: orderData.usdGrandTotal + discount,
        subtotal: totalPrice,
        revenue: orderData.usdGrandTotal,
        tax: orderData.vatAmount * orderData.usdExchangeRate,
        shipping: orderData?.deliveryCost * orderData?.usdExchangeRate,
        coupon: orderData?.couponCode,
        discount,
        currency: 'USD',
        products: mapedData,
        indexName: appState?.globalData?.indexName || 'product',
        email,
        isNewCustomer,
      };
      track('Order Completed', sentData);
    }
  };

  // WishList Event Tracking

  // Fire this event when a customer moves a product from their wish list to their cart.
  const trackProductWishlistAddedToCart = (
    orderDetailsId: number,
    product: Product,
    position: number = 0
  ): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        phone: customerState?.phone,
        email: customerState?.email,
        wishlist_id: '',
        wishlist_name: '',
        ...mapProductES(position, product, getUSDDollar()),
        cart_id: String(orderDetailsId),
      };
      track('Wishlist Product Added to Cart', sentData);
    }
  };

  // Fire this event when a customer adds a product to their wish list.
  const trackProductWishlistAdded = (
    product: Product,
    position: number = 0
  ): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        phone: customerState?.phone,
        email: customerState?.email,
        wishlist_id: '',
        wishlist_name: '',
        ...mapProductES(position, product, getUSDDollar()),
      };
      track('Product Added to Wishlist', sentData);
    }
  };

  // Fire this event when a customer removes a product from their wish list.
  const trackProductWishlistRemoved = (
    product: Product,
    position: number = 0
  ): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        phone: customerState?.phone,
        email: customerState?.email,
        wishlist_id: '',
        wishlist_name: '',
        ...mapProductES(position, product, getUSDDollar()),
      };
      track('Product Removed from Wishlist', sentData);
    }
  };

  // Promotion Event Tracking

  // Fire this event when a visitor clicks an internal offer promotion.
  const trackPromotionClicked = (bannerData: BannerModel) => {
    if (appState?.segmentLoaded) {
      track('Promotion Clicked', {
        ...bannerData,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };

  //track home page view
  const trackHomePageViewed = () => {
    if (appState?.segmentLoaded) {
      track('Home Page Viewed', {
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };

  // User Event Tracking

  // Fire this event when a user creates a new account
  const trackCreateAccount = (sentData: UserModel): void => {
    if (appState?.segmentLoaded) {
      track('Signed Up', mapSignupUserData(sentData));
    }
  };

  const identifyCreateAccount = (sentData: UserModel): void => {
    if (appState?.segmentLoaded) {
      identify(sentData?.uuid, mapSignupUserIdentifiedData(sentData));
    }
  };

  // Fire this event when a user sign in
  const trackUserSignIn = (user: UserModel): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        username: user?.email,
        is_social: !!user?.social_platform,
        social_platform: user?.social_platform,
      };
      track('Signed In', sentData);
    }
  };

  // Fire this event when a user sign in
  const trackIdentifySignIn = (user: UserModel): void => {
    if (appState?.segmentLoaded) {
      identify(user?.uuid, mapSignupUserIdentifiedData(user));
    }
  };

  // Fire this event when a user update his account
  const trackUpdateAccount = (email: string, traits: any): void => {
    if (appState?.segmentLoaded && Object.keys(traits).length) {
      const sentData = {
        traits: [traits],
        username: email,
      };
      track('User Profile Updated', sentData);
    }
  };

  const identifyUpdateAccount = (
    uuid: string,
    email: string,
    traits: any
  ): void => {
    if (appState?.segmentLoaded && Object.keys(traits).length) {
      identify(uuid, { ...traits, username: email });
    }
  };

  const identifyChangeLang = (uuid: string, lang: string): void => {
    if (lang) {
      identify(uuid, {}, lang);
    }
  };

  // Fire this event when a user sign out
  const trackUserLogOut = (email: string): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        username: email,
        is_authenticated: false,
      };
      track('Signed Out', sentData);
    }
  };

  // Seesion Start for mixpanel
  const trackSegmentMixPanelSessionStart = () => {
    if (appState?.segmentLoaded) {
      const expdata = localStore.getTimeStamp<any>(
        STORAGE_KEYS.SEGMENT_MIXPANEL_DATE
      );
      if (!expdata.HasValue) {
        const exdate: Date = localStore.gDate(new Date(), 30);
        localStore.setWithTimeStamp(
          STORAGE_KEYS.SEGMENT_MIXPANEL_DATE,
          appState?.opsId,
          exdate
        );
        track('Session Start', {});
      }
    }
  };

  // Custom Event Tracking
  //  didn't do it yet need change password page to be integrated

  // Fire when a user clicks on a button
  const trackButtonClicked = (
    button_name: string,
    section: string,
    sourceId?: number,
    page_source?: string
  ): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        button_name,
        section,
        source_id: sourceId,
        page_source,
      };
      track('Button Clicked', sentData);
    }
  };

  // Fire when the user opens the help center the my account section
  const trackHelpCenterOpen = (order_number: number): void => {
    if (appState?.segmentLoaded) {
      track('Help Center Opened', { order_number });
    }
  };

  // Fire when user changes their operation center
  const trackChangeOpsCenter = (sentData: any): void => {
    if (appState?.segmentLoaded) {
      track('Change Ops', {
        ...sentData,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };

  // Fire when user changes their operation center
  const trackChangeLanguage = (data: any): void => {
    if (appState?.segmentLoaded) {
      track('Change Language', data);
    }
  };

  // Fire when delivery slot selected
  const trackDeliverySlotSelected = (
    deliveryTimeName: string,
    slot_type?: string
  ): void => {
    if (appState?.segmentLoaded) {
      track('Delivery Slot Selected', {
        delivery_slot: deliveryTimeName,
        slot_type,
      });
    }
  };
  // Fire on unknown check in address page -checkout
  const trackUnknownAddress = (
    eventName: string,
    page_source: string,
    is_unknown_address: boolean
  ): void => {
    if (appState?.segmentLoaded) {
      track(eventName, {
        page_source,
        is_unknown_address,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };

  // Fire on annonymous checkbox in address page -checkout
  const trackAnnonymousChecked = (
    eventName: string,
    page_source: string,
    is_anonymous: boolean
  ): void => {
    if (appState?.segmentLoaded) {
      track(eventName, {
        page_source,
        is_anonymous,
      });
    }
  };

  // Fire on dropping pin on unavailable area in address page -checkout
  const trackUnavailableZones = (eventName: string): void => {
    if (appState?.segmentLoaded) {
      track(eventName, {
        action: 'Map location',
        error: 'User dropped a pin outside the available zones',
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };
  //Fire when product quantity changed
  const trackProductQuantity = (quantityAction: string, data: any): void => {
    const mappedStep = data?.newAddressSlotFlowEnabled
      ? mapNewCheckoutSteps(data.step)
      : mapCheckoutSteps(data.step);
    if (appState?.segmentLoaded) {
      track(quantityAction, {
        product_sku: data.sku,
        page_source: data?.step ? mappedStep : 'cart sidebar',
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };

  // Fire when User included note for team
  const trackNoteForTeam = (note: string): void => {
    if (appState?.segmentLoaded) {
      track('Note For Team', {
        page_source: 'Checkout',
        note,
      });
    }
  };
  //Fire when there is link in card message
  const trackLinkUpload = (link: string): void => {
    if (appState?.segmentLoaded) {
      track(link ? 'Card Link Uploaded' : 'Card Link Removed', {
        page_source: 'Card Message',
      });
    }
  };
  //Fire when country code change
  const trackCountryCode = (defaultCode: string, newCode: string): void => {
    if (appState?.segmentLoaded) {
      if (defaultCode !== '') {
        track('Country Code Tapped', {
          default_code: defaultCode,
          new_code: newCode,
          page_source: 'Address',
        });
      }
    }
  };
  //Fire when saved address selected
  const trackSavedAddressClick = (props: any = {}): void => {
    if (appState?.segmentLoaded) {
      track('Saved Address Selected', {
        page_source: 'Address',
        ...props,
      });
    }
  };
  //Address entered manually
  const trackManualAddress = (props: any = {}): void => {
    if (appState?.segmentLoaded) {
      track('Address Entered Manually', {
        page_source: 'Address',
        ...props,
      });
    }
  };

  //Card Added
  const trackCustomCardAdded = (cardData: any): void => {
    const occasionIds = cardData?.card_occasions?.map((x: any) => x.id);
    const occasionNames = cardData?.card_occasions?.map((x: any) => x.name);
    if (appState?.segmentLoaded) {
      const sentData = {
        cart_id: cardData?.cart_id,
        card_id: cardData?.card_id,
        card_sku: cardData?.card_sku,
        price: cardData?.price,
        is_custom_card: cardData?.is_custom_card,
        has_body_message: cardData?.has_body_message,
        has_video_message: cardData?.has_video_message,
        card_occasion_id: occasionIds,
        card_occasion_name: occasionNames,
        signature_type: cardData?.signature_type,
        has_signature: cardData?.has_signature,
        has_suggested_message: cardData?.has_suggested_message,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Card Added', sentData);
    }
  };
  // When the Gift Wrap Pop up is Presented
  const trackGiftWrapModalOpened = (
    giftWrapData: any,
    abTestResult?: Result<any> | undefined
  ): void => {
    if (appState?.segmentLoaded) {
      let experiment_data: any;
      if (abTestResult?.featureId) {
        experiment_data = {
          experiment_id: abTestResult?.featureId,
          variant_name: abTestResult?.key,
          variant_value: abTestResult?.value
            ? `${abTestResult?.value}`
            : undefined,
        };
      }
      const sentData = {
        order_id: giftWrapData?.cart_id,
        product_parent_id: giftWrapData?.cartItemId,
        vas_product_id: giftWrapData?.id,
        gift_sku: giftWrapData?.gift_sku,
        price: giftWrapData?.price,
        experiment_data,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Gift Wrap Engagement', sentData);
    }
  };
  // When the Vas product is added
  const trackProductVasAdded = (giftWrapData: any): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        order_id: giftWrapData?.cart_id,
        product_parent_id: giftWrapData?.parent_id,
        vas_product_id: giftWrapData?.id,
        gift_sku: giftWrapData?.gift_sku,
        price: giftWrapData?.price,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Gift Wrap Added', sentData);
    }
  };

  //addons
  const trackAddonsPopupViewed = () => {
    if (appState?.segmentLoaded) {
      const sentData = {
        addon_category_name: 'Best Match',
        addon_category_ranking: 1,
        page_source: 'add-on',
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('AddOn Pop Out Viewed', sentData);
    }
  };

  const trackAddonsAddedToCart = (addon: any, source: string) => {
    if (appState?.segmentLoaded) {
      const sentData = {
        addon_product_id: addon?.proId,
        addon_product_name: addon?.productName,
        addon_product_brand: addon?.categories?.brand,
        addon_product_category: addon?.categories?.category,
        addon_quantity: 1,
        addon_position: addon?.position,
        addon_category_name: addon?.categoryName,
        addon_product_price: Number(addon?.price)?.toFixed(3),
        addon_product_currency: appState?.currency,
        addon_category_ranking: addon?.categoryRank || 1,
        page_source: source,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Add On Added', sentData);
    }
  };

  const trackIncreaseAddonsQTY = (addon: any, qty: number, source: string) => {
    if (appState?.segmentLoaded) {
      const sentData = {
        addon_product_id: addon?.proId,
        addon_product_name: addon?.productName,
        addon_product_brand: addon?.categories?.brand,
        addon_product_category: addon?.categories?.category,
        addon_quantity: qty,
        addon_position: addon?.position,
        addon_category_name: addon?.categoryName,
        addon_product_price: Number(addon?.price)?.toFixed(3),
        addon_product_currency: appState?.currency,
        addon_category_ranking: addon?.categoryRank || 1,
        page_source: source,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Increase Quantity', sentData);
    }
  };

  const trackDecreaseAddonsQTY = (addon: any, qty: number, source: string) => {
    if (appState?.segmentLoaded) {
      const sentData = {
        addon_product_id: addon?.proId,
        addon_product_name: addon?.productName,
        addon_product_brand: addon?.categories?.brand,
        addon_product_category: addon?.categories?.category,
        addon_quantity: qty,
        addon_position: addon?.position,
        addon_category_name: addon?.categoryName,
        addon_product_price: Number(addon?.price)?.toFixed(3),
        addon_product_currency: appState?.currency,
        addon_category_ranking: addon?.categoryRank || 1,
        page_source: source,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Decrease Quantity', sentData);
    }
  };

  const trackAddonCategoryChanged = (
    categoryName: string,
    categoryRank: number
  ) => {
    if (appState?.segmentLoaded) {
      const sentData = {
        addon_category_name: categoryName,
        addon_category_ranking: categoryRank,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('AddOn Category Changed', sentData);
    }
  };

  // When the Vas product is removed
  const trackProductVasRemoved = (giftWrapData: any): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        order_id: giftWrapData?.cart_id,
        product_parent_id: giftWrapData?.parent_id,
        vas_product_id: giftWrapData?.id,
        gift_sku: giftWrapData?.gift_sku,
        price: giftWrapData?.price,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Gift Wrap Removed', sentData);
    }
  };

  const trackPurchaseWithGiftWrap = (
    orderId: number,
    abTestResult?: Result<any> | undefined
  ): void => {
    if (appState?.segmentLoaded) {
      let experiment_data: any;
      if (abTestResult?.featureId) {
        experiment_data = {
          experiment_id: abTestResult?.featureId,
          variant_name: abTestResult?.key,
          variant_value: abTestResult?.value
            ? `${abTestResult?.value}`
            : undefined,
        };
      }
      track('Purchase With Gift Wrap', {
        experiment_data,
        order_number: orderId,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };
  const trackPurchaseWithCustomCard = (
    orderId: number,
    cardId: number,
    abTestResult?: Result<any> | undefined
  ): void => {
    if (appState?.segmentLoaded) {
      let experiment_data: any;
      if (abTestResult?.featureId) {
        experiment_data = {
          experiment_id: abTestResult?.featureId,
          variant_name: abTestResult?.key,
          variant_value: abTestResult?.value
            ? `${abTestResult?.value}`
            : undefined,
        };
      }
      track('Purchase With Custom Card', {
        experiment_data,
        order_number: orderId,
        card_id: cardId,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };
  const trackGiftAgain = ({
    orderId,
    newOrderId,
    pageSource,
    isEligible = false,
  }: GiftAgainProps): void => {
    if (appState?.segmentLoaded) {
      track('Gift It Again', {
        old_order_id: orderId,
        new_order_id: newOrderId,
        screen_name: pageSource,
        eligible_for_free_delivery: isEligible,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };

  const trackOnContinueOOSBtn = ({
    orderId,
    pageSource,
    isEligible = false,
    product_ids,
    newOrderId,
  }: OosDataProps): void => {
    if (appState?.segmentLoaded) {
      track('Continue OOS Failure', {
        old_order_id: orderId,
        new_order_id: newOrderId,
        screen_name: pageSource,
        eligible_for_free_delivery: isEligible,
        product_ids,
      });
    }
  };

  const trackOnStartShoppingOOSBtn = ({
    orderId,
    pageSource,
    isEligible = false,
    product_ids,
  }: OosDataProps): void => {
    if (appState?.segmentLoaded) {
      track('Start Shopping OOS Failure', {
        order_id: orderId,
        screen_name: pageSource,
        eligible_for_free_delivery: isEligible,
        product_ids,
      });
    }
  };

  // Subscription
  const trackSubscriptionStarted = (subsData: any): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        subscription_id: String(subsData?.identifier),
        subscription_frequency: subsData?.frequencyName,
        subscription_duration: subsData?.durationName,
        cost_per_delivery: subsData?.pricePerDelivery,
        discount_amount: subsData?.discount - subsData?.pricePerDelivery,
        subscription_start_date: subsData?.startAt,
        subscription_tier_id: subsData?.subscriptionTierId,
        number_of_deliveries: subsData?.deliveryTimes,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Subscription Started', sentData);
    }
  };
  const trackSubscriptionCompleted = (
    orderData: any,
    subscriptionId: string
  ): void => {
    if (appState.segmentLoaded) {
      const sentData = {
        subscription_id: String(subscriptionId),
        recipient_name: orderData?.address?.recipientName,
        recipient_phone_number: orderData?.address?.recipientPhone,
        collect_recipient_address: orderData?.address?.collectRecipientAddress,
        recipient_address: orderData?.address?.addressLineOne,
        sub_total: orderData?.orderDetails?.subTotal * getUSDDollar(),
        discount: orderData?.orderDetails?.discount,
        delivery_charge:
          orderData?.orderDetails?.deliveryCharge * getUSDDollar(),
        vat_amount: orderData?.orderDetails?.vatAmount * getUSDDollar(),
        vat_percentage: orderData?.orderDetails?.vatPercentage,
        next_payment_date: orderData?.orderDetails?.nextPaymentDate,
        final_price: orderData?.orderDetails?.finalPrice * getUSDDollar(),
        currency: 'USD',
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Subscription Completed', sentData);
    }
  };
  const trackSubscriptionTierSelected = (id: number, page_source: string) => {
    if (appState?.segmentLoaded) {
      track('Tier Selected', {
        subscription_tier_id: id,
        page_source,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };
  const trackSubscriptionPageLanded = (page_source: string) => {
    if (appState?.segmentLoaded) {
      track('Subscription Page Landed', {
        landing_page_source: page_source,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };

  const trackSubscriptionPaymentInfoEntered = (
    id: string,
    payment_method: string
  ): void => {
    if (appState?.segmentLoaded) {
      const sentData = {
        subscription_id: String(id),
        shipping_method: '',
        payment_method,
        phone: customerState?.phone,
        email: customerState?.email,
      };
      track('Payment Info Entered', sentData);
    }
  };

  const trackSubscriptionEditPaymentDetails = (
    id: string,
    orderId: number | undefined | null,
    paymentMethod: string
  ) => {
    if (appState?.segmentLoaded) {
      track('Subscription Edit Payment Details', {
        subscription_Id: id,
        order_id: orderId,
        current_payment_method: paymentMethod,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };
  const trackViewMySubscription = () => {
    if (appState?.segmentLoaded) {
      track('View my Subscriptions', {
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };
  const trackSubscriptionClicked = (id: string) => {
    if (appState?.segmentLoaded) {
      track('Subscription Clicked', {
        subscription_id: id,
        phone: customerState?.phone,
        email: customerState?.email,
      });
    }
  };

  // Helpers
  const getUSDDollar = () => {
    return appState.currentOpsCenter?.usdExchangeRate || 3.3;
  };
  const getHashedemail = () => {
    return localStore.get(STORAGE_KEYS.HASHED_EMAIL, false) || '';
  };

  const getTotalValue = (dataObj: ProductModel, qty = 0) => {
    return dataObj.Price * (qty > 0 ? qty : 1);
  };

  const getTotalValueES = (dataObj: Product, qty = 0) => {
    return dataObj.productAvailability.price * (qty > 0 ? qty : 1);
  };

  const mapCartProduct = (index: number, cartProduct: any, USD: number) => {
    const price = cartProduct.price * USD;
    const proObj = {
      product_id: String(cartProduct?.proId),
      sku: cartProduct.sku,
      name: cartProduct.name,
      price: price?.toFixed(3),
      category: '',
      position: index + 1,
      url: '',
      image_url: cartProduct?.image,
      queryID: cartProduct?.algoliaQueryId,
    };
    return proObj;
  };
  const getTotalValueFromCart = (cartProduct: any, qty = 1): number => {
    return cartProduct.price * getUSDDollar() * (qty > 0 ? qty : 1);
  };

  const mapProduct = (
    index: number,
    product: ProductModel,
    USD: number,
    qty = 1
  ) => {
    const proObj = {
      product_id: String(product.Id),
      sku: product.SKU,
      category: product.CategoryObj?.Name ? product.CategoryObj?.Name : '',
      name: product.Name,
      brand: '',
      variant: '',
      price: product.Price * USD,
      quantity: qty,
      coupon: '',
      position: index + 1,
      url: getProductUrl(product),
      image_url: product.ImgURL,
    };
    return proObj;
  };

  const mapProductES = (
    index: number,
    product: Product,
    USD: number,
    qty = 1,
    categoryName?: string
  ) => {
    const proObj = {
      product_id: String(product.id),
      sku: product.sku,
      category: categoryName ? categoryName : '',
      name: product?.nameEn,
      brand: '',
      variant: '',
      price: (product?.productAvailability?.priceWithVat * USD)?.toFixed(3),
      quantity: qty,
      coupon: '',
      position: index + 1,
      url: getProductESUrl(product),
      image_url: product?.images?.coverImage,
    };
    return proObj;
  };

  const getProductUrl = (product: ProductModel) => {
    const productUrl = formatPDPUrl({
      slug: product.Slug,
      id: product.Id,
      opsUrl: appState.opsUrl,
    });
    return productUrl;
  };

  const getProductESUrl = (product: Product) => {
    const productUrl = formatPDPUrl({
      slug: product.slug,
      id: product.id,
      opsUrl: appState.opsUrl,
    });
    return productUrl;
  };

  const mapCheckoutSteps = (step: number): string => {
    switch (step) {
      case CheckoutStep.Date:
        return 'Delivery Times';

      case CheckoutStep.Message:
        return 'Card Message';

      case CheckoutStep.Address:
        return 'Address';

      case CheckoutStep.Payment:
        return 'Payment';

      case CheckoutStep.Reciept:
        return 'Reciept';

      default:
        return 'Delivery Times';
    }
  };

  const mapNewCheckoutSteps = (step: number): string => {
    switch (step) {
      case CheckoutSteps.Message:
        return 'Card Message';

      case CheckoutSteps.DeliveryDetails:
        return 'Delivery Details';

      case CheckoutSteps.Payment:
        return 'Payment';

      case CheckoutSteps.Reciept:
        return 'Reciept';

      default:
        return 'Delivery Details';
    }
  };

  // Mapping Signup User Data
  const mapSignupUserData = (user: UserModel) => {
    return {
      signup_type: '',
      first_name: user?.name,
      last_name: user?.lastName,
      email: user?.email,
      phone: String('+' + user?.country?.code + user?.phone),
      username: user?.email,
      gender: user?.gender === 1 ? 'F' : 'M', // M = male or F = female
      birthDate: transformBirthDateFormat(new Date(user?.birthDate)),
    };
  };

  const transformBirthDateFormat = (birthDate: Date) => {
    if (birthDate) {
      const birthDateFormat = new Date(convertDateToYMD(birthDate));
      return convertDateToYMD(birthDateFormat);
    } else {
      return '';
    }
  };

  const mapSignupUserIdentifiedData = (user: UserModel) => {
    const age = user.birthDate ? calculateAge(new Date(user?.birthDate)) : 0;
    return {
      firstName: user?.name,
      lastName: user?.lastName,
      email: user?.email,
      phone: String('+' + user?.country?.code + user?.phone),
      username: user?.email,
      gender: user?.gender === 1 ? 'F' : 'M', // M = male or F = female
      birthdate: transformBirthDateFormat(new Date(user?.birthDate)),
      id: user?.id,
      name: user?.fullName,
      title: '',
      address: {},
      age,
      currency: CURRENCY_CODE,
      newsletters: user?.newsletter,
      community_member: false,
      loyalty_registered: user?.loyaltyRegistered,
    };
  };

  const calculateAge = (birthday: Date) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const mapFilters = (filter: any): Filter => {
    const mappedFilter: Filter = {
      type: filter.categoryName,
      value: filter.Key,
    };
    return mappedFilter;
  };

  const mapSorts = (sortType: number): Filter => {
    let mappedSort: Filter;
    switch (sortType) {
      case SortType.Recommended:
        mappedSort = {
          type: 'recommended',
          value: 'true',
        };
        break;
      case SortType.PriceLTH:
        mappedSort = {
          type: 'price',
          value: 'asc',
        };
        break;
      case SortType.PriceHTL:
        mappedSort = {
          type: 'price',
          value: 'desc',
        };
        break;
      case SortType.New:
        mappedSort = {
          type: 'new',
          value: 'true',
        };
        break;
      default:
        mappedSort = {
          type: 'recommended',
          value: 'true',
        };
    }
    return mappedSort;
  };

  const trackABExperimentsEvents = (eventName: string, props?: any): void => {
    track(eventName, props);
  };

  const trackABExperimentsView = (
    result: Result<any>,
    orderId?: number,
    props = {}
  ): void => {
    if (result?.featureId) {
      track('Experiment Viewed', {
        experiment_id: result?.featureId,
        variant_name: result?.key,
        variant_value: `${result?.value}`,
        orderId,
        ...(props || {}),
      });
    }
  };

  const trackCheckoutAddressAndSlots = (
    eventName: string,
    props?: any
  ): void => {
    track(eventName, props);
  };

  const trackUseMyWallet = (orderId: number, isSelected: boolean): void => {
    track('Use Credits', {
      is_selected: isSelected,
      order_id: orderId,
    });
  };
  //gift sets events
  const trackCustomizeGiftBoxButton = (
    props?: CustomizButtonEventProps
  ): void => {
    const sentData = {
      giftset_id: props?.giftsetProductId,
      source: props?.source,
      source_id: props?.sourceId,
    };
    track('Customize button', sentData);
  };
  const trackGiftBoxModalSingleSelect = (selectedItemId: number): void => {
    const sentData = {
      selected_product_id: selectedItemId,
    };
    track('Done selected item', sentData);
  };
  const trackGiftBoxModalGroupeSelect = (selectedItemIds?: number[]): void => {
    const sentData = {
      selected_product_Ids: selectedItemIds,
    };
    track('Done giftset', sentData);
  };
  const trackClickSingleGroup = (
    giftsetId: number,
    defaultProductId: number
  ): void => {
    const sentData = {
      giftset_id: giftsetId,
      default_product_id: defaultProductId,
    };
    track('Customized product viewed', sentData);
  };

  const trackHomePageClicks = (data?: any): void => {
    track('Homepage Section Clicked', data);
  };

  return {
    track,
    trackProductSearch,
    trackProductAdded,
    trackProductESAdded,
    trackBothProductsESAdded,
    trackProductRemoved,
    trackCartViewed,
    trackCheckoutStarted,
    trackPromotionClicked,
    trackUpdateAccount,
    identifyUpdateAccount,
    trackUserLogOut,
    trackSegmentMixPanelSessionStart,
    trackButtonClicked,
    trackChangeOpsCenter,
    trackChangeLanguage,
    resetSegment,
    trackProductWishlistAddedToCart,
    trackProductClicked,
    trackProductESClicked,
    trackProductViewed,
    trackProductESViewed,
    trackQuickFIlterClicked,
    trackCouponEntered,
    trackCouponApplied,
    trackCouponDenied,
    trackCheckoutStepCompleted,
    trackCheckoutStepViewed,
    trackMOVNotReached,
    trackPaymentInfoEntered,
    trackProductWishlistAdded,
    trackProductWishlistRemoved,
    trackCreateAccount,
    identifyCreateAccount,
    identifyChangeLang,
    trackUserSignIn,
    trackIdentifySignIn,
    trackHelpCenterOpen,
    trackDeliverySlotSelected,
    trackProductListViewed,
    trackProductListFiltered,
    initalSegmentLoad,
    trackPage,
    trackOrderCompleted,
    trackUnknownAddress,
    trackAnnonymousChecked,
    trackProductQuantity,
    trackNoteForTeam,
    trackLinkUpload,
    trackCountryCode,
    trackSavedAddressClick,
    trackManualAddress,
    trackUnavailableZones,
    trackHomePageViewed,
    trackAlgoliaAbTesting,
    trackSearchClicked,
    trackABExperimentsEvents,
    trackABExperimentsView,
    isSegmentLoaded,
    isSegmentEnabled,
    trackMenuItemClicked,
    trackExternalLinkClicked,
    trackViewAllClicked,
    trackCheckoutAddressAndSlots,
    trackCustomCardAdded,
    trackUseMyWallet,
    trackGiftWrapModalOpened,
    trackProductVasAdded,
    trackProductVasRemoved,
    trackPurchaseWithGiftWrap,
    trackPurchaseWithCustomCard,
    trackProductModal,
    trackCheckoutError,
    trackCustomizeGiftBoxButton,
    trackGiftBoxModalSingleSelect,
    trackGiftBoxModalGroupeSelect,
    trackClickSingleGroup,
    trackGiftAgain,
    trackOnContinueOOSBtn,
    trackOnStartShoppingOOSBtn,
    trackHomePageClicks,
    trackAddonsAddedToCart,
    trackAddonsPopupViewed,
    trackDecreaseAddonsQTY,
    trackIncreaseAddonsQTY,
    trackAddonCategoryChanged,
    trackSubscriptionStarted,
    trackSubscriptionCompleted,
    trackSubscriptionTierSelected,
    trackSubscriptionPaymentInfoEntered,
    trackSubscriptionPageLanded,
    trackSubscriptionEditPaymentDetails,
    trackViewMySubscription,
    trackSubscriptionClicked,
  };
};
