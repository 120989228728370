import React, { createContext, useReducer } from 'react';
export interface CustomerStateProps {
  user?: any | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  walletBalance?: number;
  tempAuth?: any;
}

export interface CustomerActionProps {
  type: string;
  payload?: any;
}

export const defaultCustomerContext: CustomerStateProps = {
  user: null,
  isLoading: true,
  isLoggedIn: false,
  walletBalance: 0,
  tempAuth: null,
};

export const SET_IS_CUSTOMER_LOADING = 'SET_IS_CUSTOMER_LOADING';
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const SET_CUSTOMER_WALLET = 'SET_CUSTOMER_WALLET';
export const SET_CUSTOMER_POINTS = 'SET_CUSTOMER_POINTS';
export const SET_CUSTOMER_TEMP_AUTH_DATA = 'SET_CUSTOMER_TEMP_AUTH_DATA';

export const CustomerContext = createContext<any>(defaultCustomerContext);

export const customerReducer = (
  state: CustomerStateProps,
  action: CustomerActionProps
): any => {
  switch (action.type) {
    case SET_CUSTOMER_DATA:
      return { ...state, isLoading: false, ...action.payload };
    case SET_IS_CUSTOMER_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_CUSTOMER_WALLET:
      return { ...state, walletBalance: action.payload };
    case SET_CUSTOMER_POINTS:
      return { ...state, userPoints: action.payload };
    case SET_CUSTOMER_TEMP_AUTH_DATA:
      return { ...state, tempAuth: action.payload };
    default:
      return state;
  }
};

export const CustomerContextProvider: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  const [customerState, customerDispatch] = useReducer(
    customerReducer,
    defaultCustomerContext
  );
  const customerConfig = {
    customerState,
    customerDispatch,
  };

  return (
    <CustomerContext.Provider value={customerConfig}>
      {props.children}
    </CustomerContext.Provider>
  );
};
