import cx from 'clsx';
import { useLocation } from 'react-router-dom';
import IconLogoX from '@flowardco/fui-icons/src/IconLogoX';
import { redirectToRoute } from '@flowardco/flib-helpers';
import IconHeadset from '@flowardco/fui-icons/src/IconHeadset';

export const Header = ({
  language = 'en',
  showCustomerSupport = false,
  showLanguageSwitch = true,
  onCustomerSupportClick = () => null,
}: {
  language: string;
  showCustomerSupport?: boolean;
  onCustomerSupportClick?: () => void;
  showLanguageSwitch?: boolean;
}) => {
  const location = useLocation();
  const onLangugeSwitch = (e: any, inArabic: boolean) => {
    e.preventDefault();
    const currentUrl = location.pathname.split(
      `/${inArabic ? 'ar' : 'en'}/`
    )?.[1];
    let locationSearch = location.search;
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('q');

    if (query) {
      searchParams.set('q', decodeURI(query));
      locationSearch = `?${searchParams.toString()}`;
    }

    const newUrl = `/${inArabic ? 'en' : 'ar'}/`.concat(
      currentUrl,
      locationSearch
    );
    return redirectToRoute(newUrl);
  };
  return (
    <header className='fui-sticky fui-top-0 fui-z-1000 fui-mb-4 fui-flex fui-w-full fui-flex-1 fui-items-center fui-justify-between fui-bg-white fui-p-4'>
      {showLanguageSwitch ? (
        <button
          type='button'
          className={cx(
            'fui-inline-block fui-cursor-pointer fui-text-md ltr:fui-float-left',
            language === 'ar' ? 'fui-font-english' : 'fui-font-arabic'
          )}
          onClick={(e) => onLangugeSwitch(e, language === 'ar')}
        >
          {language === 'ar' ? 'English' : 'العربية'}
        </button>
      ) : (
        <div />
      )}

      <div className='fui-w-[90px]'>
        <IconLogoX className='fui-block fui-w-full fui-text-theme-navy-925' />
      </div>

      {showCustomerSupport ? (
        <button
          type='button'
          className={cx(
            'fui-inline-block fui-h-8 fui-w-8 fui-cursor-pointer fui-appearance-none fui-rounded-lg fui-bg-gray-50 fui-p-1.5 fui-text-md fui-text-black-100 fui-shadow-flwBtn fui-transition-colors fui-duration-200 hover:fui-text-navy-500 ltr:fui-float-left'
          )}
          onClick={onCustomerSupportClick}
        >
          <IconHeadset className='fui-w-full' />
        </button>
      ) : (
        <div className='fui-w-6' />
      )}
    </header>
  );
};
