import React, { createContext, useReducer } from 'react';
import type { AddonItemProps } from '@flowardco/fui-addons';
import type { CartItemProps } from '@flowardco/fui-cart-list';
import type {
  CheckoutCart,
  CardMessageInterface,
  CartItemsInfo,
  CartValidationResult,
} from '@flowardco/flib-models';

export interface CartStateProps {
  cartData?: CheckoutCart | null;
  cartCount?: number;
  isLoading: boolean;
  cartListItems: CartItemsInfo | null;
  isAddonProcessing: boolean;
  isCheckoutAddonsError: boolean;
  addOns?: any[];
  isBtnLoading: boolean;
  cardMessageData: CardMessageInterface | null;
  addonsCategories: Array<{ name: string; id: number }>;
  checkoutAddons: AddonItemProps[];
  cartItemProcessing: CartItemProps[];
  showCartSidebar: boolean;
  shippingAddress?: any;
  giftWrapList?: any;
  isGiftWrapListLoading: boolean;
  cartValidationResult: CartValidationResult;
  repurchaseFreeDeliveryData?: any;
}

export interface CartActionProps {
  type: string;
  payload?: any;
}

export const defaultCartContext: CartStateProps = {
  cartData: null,
  cartCount: 0,
  cartListItems: null,
  isLoading: true,
  isAddonProcessing: false,
  isCheckoutAddonsError: true,
  addOns: [],
  isBtnLoading: false,
  cardMessageData: null,
  addonsCategories: [],
  checkoutAddons: [],
  cartItemProcessing: [],
  showCartSidebar: false,
  shippingAddress: null,
  giftWrapList: null,
  isGiftWrapListLoading: false,
  cartValidationResult: {
    cartValidationItems: [],
    mainErrorMessage: '',
  },
  repurchaseFreeDeliveryData: null,
};

export const SET_IS_CART_LOADING = 'SET_IS_CART_LOADING';
export const SET_CART_DATA = 'SET_CART_DATA';
export const SET_CART_ITEMS_DATA = 'SET_CART_ITEMS_DATA';
export const SET_CART_SHIPPING_ADDRESS = 'SET_CART_SHIPPING_ADDRESS';
export const SET_ADDONS_DATA = 'SET_ADDONS_DATA';
export const SET_ADDONS_PROCESSING = 'SET_ADDONS_PROCESSING';
export const SET_ADDON_PROCESSING = 'SET_ADDON_PROCESSING';
export const SAVE_PAYMENT_LOADING = 'SAVE_PAYMENT_LOADING';
export const SAVE_PAYMENT_ERROR = 'SAVE_PAYMENT_ERROR';
export const SET_CARD_MESSAGE_DATA = 'SET_CARD_MESSAGE_DATA';
export const FETCH_ADDONS_CATEGORIES = 'FETCH_ADDONS_CATEGORIES';
export const FETCH_CHECKOUT_ADDONS = 'FETCH_CHECKOUT_ADDONS';
export const FETCH_CHECKOUT_ADDONS_ERROR = 'FETCH_CHECKOUT_ADDONS_ERROR';
export const RESET_CHECKOUT_ADDONS = 'RESET_CHECKOUT_ADDONS';
export const ADD_ITEM_PROCESSING = 'ADD_ITEM_PROCESSING';
export const REMOVE_ITEM_PROCESSING = 'REMOVE_ITEM_PROCESSING';
export const SHOW_CART_SIDEBAR = 'SHOW_CART_SIDEBAR';
export const FETCH_GIFT_WRAP_LIST = 'FETCH_GIFT_WRAP_LIST';
export const FETCH_REPURCHASE_FREE_DELIVERY_DATA =
  'FETCH_REPURCHASE_FREE_DELIVERY_DATA';
export const CartContext = createContext<any>(defaultCartContext);

export const cartReducer = (
  state: CartStateProps,
  action: CartActionProps
): any => {
  switch (action.type) {
    case SET_CART_DATA:
      return { ...state, ...action.payload };
    case SET_CART_ITEMS_DATA: {
      const cartCount = action?.payload?.cartCount || 0;
      const cartValidationResult = action?.payload?.cartValidationResult || {
        cartValidationItems: [],
        mainErrorMessage: '',
      };
      return {
        ...state,
        cartData: { ...(state?.cartData || {}), ...(action?.payload || {}) },
        cartListItems: action.payload,
        cartValidationResult,
        cartCount,
        isLoading: false,
      };
    }
    case SET_CART_SHIPPING_ADDRESS:
      return { ...state, shippingAddress: action.payload, isLoading: false };
    case SET_IS_CART_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_ADDONS_DATA:
      return { ...state, addOns: action.payload };
    case SET_ADDONS_PROCESSING:
      return { ...state, isAddonProcessing: action.payload };
    case SET_ADDON_PROCESSING:
      return { ...state, checkoutAddons: action.payload };
    case SAVE_PAYMENT_LOADING: {
      return {
        ...state,
        isBtnLoading: action.payload.isBtnLoading,
      };
    }
    case SAVE_PAYMENT_ERROR:
      return {
        ...state,
        isBtnLoading: false,
      };
    case SET_CARD_MESSAGE_DATA:
      return { ...state, cardMessageData: action.payload };
    case FETCH_ADDONS_CATEGORIES:
      return {
        ...state,
        isLoading: false,
        isError: false,
        addonsCategories: action.payload,
      };
    case FETCH_CHECKOUT_ADDONS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isCheckoutAddonsError: false,
        checkoutAddons: action.payload,
      };
    case FETCH_CHECKOUT_ADDONS_ERROR:
      return {
        ...state,
        isCheckoutAddonsError: true,
      };
    case RESET_CHECKOUT_ADDONS:
      return {
        ...state,
        checkoutAddons: [],
      };
    case ADD_ITEM_PROCESSING: {
      const cartItemProcessing = state?.cartItemProcessing?.concat(
        action.payload
      );
      return {
        ...state,
        cartItemProcessing,
      };
    }
    case REMOVE_ITEM_PROCESSING: {
      const cartItemProcessing = state?.cartItemProcessing?.filter(
        (item) => item.id !== action.payload?.id
      );
      return {
        ...state,
        cartItemProcessing,
      };
    }
    case SHOW_CART_SIDEBAR:
      return { ...state, showCartSidebar: action.payload };
    case FETCH_GIFT_WRAP_LIST:
      return {
        ...state,
        isError: false,
        isGiftWrapListLoading: action.payload.isGiftWrapListLoading,
        giftWrapList: action.payload.giftWrapList,
      };
    case FETCH_REPURCHASE_FREE_DELIVERY_DATA:
      return {
        ...state,
        repurchaseFreeDeliveryData: action.payload,
      };
    default:
      return state;
  }
};

export const CartContextProvider: React.FC<{ children?: React.ReactNode }> = (
  props
) => {
  const [cartState, cartDispatch] = useReducer(cartReducer, defaultCartContext);
  const cartConfig = {
    cartState,
    cartDispatch,
  };

  return (
    <CartContext.Provider value={cartConfig}>
      {props.children}
    </CartContext.Provider>
  );
};
