import { useEffect, useState } from 'react';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { Navigate, useLoaderData } from 'react-router-dom';
import Hero from '@/components/Shared/Hero';
import RacipientFeelingsCard from '@/components/RecipientMessage/RacipientFeelingsCard';
import RateExperience from '@/components/Shared/RateExperience/RateExperience';
import { DownloadApp } from '@flowardco/fui-download-app';
import { PageLayout } from '@/components/PageLayout';
import ShareMedia from '@/components/RecipientMessage/ShareMedia';
import { localStore } from '@flowardco/flib-helpers';
import { STORAGE_KEYS } from '@/lib/helpers/constants';

const RecipientMessageCompleted = () => {
  const messageInfo: any = useLoaderData();
  const [userVisited] = useState(
    localStore.get(
      `${STORAGE_KEYS.VISITED_ORDER}${messageInfo?.orderId}`,
      false
    ) === 'true'
  );
  const { __T } = useLocalTranslator();
  // If message not sent yet, then redirect to leave message page
  if (!messageInfo?.recipientFeelings?.hasRecipientSharedFeeling) {
    return <Navigate to={'../leavemsg'} />;
  }

  useEffect(() => {
    localStore.set(
      `${STORAGE_KEYS.VISITED_ORDER}${messageInfo?.orderId}`,
      true,
      false
    );
  }, []);

  return (
    <PageLayout>
      <div className='fui-block'>
        {userVisited &&
          messageInfo?.senderFeelings?.hasSenderSharedFeelings && (
            <Hero messageInfo={messageInfo} __T={__T} />
          )}
        <div className='fui-text-center'>
          <RacipientFeelingsCard
            title={__T('Your message has been sent to {sender}', {
              sender: messageInfo?.senderFeelings?.isSenderSharingDetails
                ? messageInfo?.senderFeelings?.senderName
                : __T('the sender'),
            })}
            url={messageInfo?.recipientFeelings?.feelingLink || ''}
            isVideo={messageInfo?.recipientFeelings?.mediaType === 1}
            message={messageInfo?.recipientFeelings?.message || ''}
            titleClass='fui-my-4 fui-text-2xl fui-mx-7'
            msgClass='fui-text-base'
            page='completed'
          />
        </div>
        {messageInfo?.recipientFeelings?.feelingLink && (
          <ShareMedia
            media={messageInfo?.recipientFeelings?.feelingLink}
            isvideo={messageInfo?.recipientFeelings?.mediaType == 1}
            orderId={messageInfo?.orderId}
          />
        )}
        <div className='fui-px-4'>
          <RateExperience data={messageInfo} />
        </div>
        <div className='-fui-mx-4 -fui-mb-4 fui-pt-9'>
          <DownloadApp
            header={__T('Discover the new Floward app')}
            subHeader={__T(
              'Use "WELCOME" to get a 10% discount on your first order'
            )}
            className='fui-bg-gray-75'
            imageHolderClass='fui-bg-white'
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default RecipientMessageCompleted;
