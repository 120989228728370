import { useFeatureValue } from '@growthbook/growthbook-react';

export interface ecipientExperimentFlagssHook {
  isMarketingConsentEnabled: () => Array<{ opsId: number; enable: boolean }>;
}

export const useRecipientExperimentFlags = (): ecipientExperimentFlagssHook => {
  const isMarketingConsentEnabled = () => {
    const marketingConsentEnabled = useFeatureValue(
      'enable-location-link-marketing-consent',
      []
    );
    return marketingConsentEnabled;
  };

  return {
    isMarketingConsentEnabled,
  };
};
