import { Suspense, useState } from 'react';
import { AddressHero } from '@/components/AddressDoneHero';
import { AddressDetails } from '@/components/AddressDoneDetails';
import { Await, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@flowardco/fui-button/src';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import type { RecipientAddressModel } from '@flowardco/flib-models';
import { PageLayout } from '@/components/PageLayout';
import {
  isOrderCancelled,
  isOutForDelivery,
} from '@/lib/helpers/formatAddressData';
import { DownloadApp } from '@flowardco/fui-download-app';
import CallCenterDrawer from '@/components/AddressPageUI/CallCenterDrawer';
import { AddressPageSkeleton } from '@/components/AddressPageUI/AddressPageSkeleton';
import { PageError } from '@/components/PageError';

const AddressDonePage = () => {
  const { orderData } = useLoaderData() as { orderData: RecipientAddressModel };
  const [showCallCenter, setShowCallCenter] = useState<boolean>(false);
  const { lang = 'en' } = useParams();
  const navigate = useNavigate();
  const { __T } = useLocalTranslator();
  const handleEditButton = () => {
    navigate('../address');
  };
  const onCustomerSupportClick = () => {
    setShowCallCenter((prevState) => !prevState);
  };

  return (
    <PageLayout
      showCustomerSupport
      onCustomerSupportClick={onCustomerSupportClick}
    >
      <Suspense fallback={<AddressPageSkeleton />}>
        <Await resolve={orderData}>
          {(resolvedOrderData) => {
            if (!resolvedOrderData?.orderInfo?.id) {
              return <PageError message={'Order Not Found'} />;
            }
            return (
              <>
                {showCallCenter && (
                  <CallCenterDrawer
                    opsId={resolvedOrderData?.orderInfo?.ops?.id}
                    lang={lang}
                    onClose={() => {
                      setShowCallCenter(false);
                    }}
                    __T={__T}
                  />
                )}
                <div className='fui-px-4'>
                  <AddressHero
                    steps={resolvedOrderData?.orderInfo?.arrangingSteps}
                    orderId={resolvedOrderData?.orderInfo?.id}
                  />
                  {!isOutForDelivery(resolvedOrderData?.orderInfo) &&
                    !isOrderCancelled(resolvedOrderData?.orderInfo) && (
                      <Button
                        className='fui-mb-6 fui-h-[34px] fui-w-full'
                        rounded='md'
                        onClick={handleEditButton}
                      >
                        {__T('Edit delivery details')}
                      </Button>
                    )}
                  <AddressDetails data={resolvedOrderData?.orderInfo} />
                  <div className='-fui-mx-4 -fui-mb-4 fui-pt-9'>
                    <DownloadApp
                      header={__T('Discover the new Floward app')}
                      subHeader={__T(
                        'Use "WELCOME" to get a 10% discount on your first order'
                      )}
                      className='fui-bg-gray-75'
                      imageHolderClass='fui-bg-white'
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Await>
      </Suspense>
    </PageLayout>
  );
};

export default AddressDonePage;
