import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';

export interface CheckoutExperiementFlagsHook {
  isCustomCardEnabled: () => boolean;
  getGeoLocationEnabledOps: () => Array<{ opsId: number }>;
  getNewMsgsEnabledOps: () => Array<{ opsId: number }>;
  isGiftWrapEnabled: () => number;
  isCartCheckoutValidEnabled: () => boolean;
  isNewCardFlowEnabled: () => boolean;
  isRepurchaseFreeDeliveryEnabled: () => boolean;
  MOVExperimentValue: () => number;
  isCashGiftingEnabled: (opsId: number) => boolean;
}

export const useCheckoutExperiementFlags = (): CheckoutExperiementFlagsHook => {
  const isCustomCardEnabled = () => {
    const customCardEnabled = useFeatureIsOn(
      'enable-web-checkout-vas-custom-card'
    );
    return customCardEnabled;
  };

  const getGeoLocationEnabledOps = () => {
    const mapMasterEnabled = useFeatureValue(
      'enable-web-checkout-map-master',
      []
    );
    return mapMasterEnabled;
  };

  const isGiftWrapEnabled = () => {
    const expandedGiftWrapEnabled = useFeatureValue(
      'enable-web-checkout-vas-gift-wrapping',
      2
    );
    return expandedGiftWrapEnabled;
  };

  const getNewMsgsEnabledOps = () => {
    const newCardMsgsEnabled = useFeatureValue(
      'enable-web-checkout-card-messages',
      []
    );
    return newCardMsgsEnabled;
  };

  const isCartCheckoutValidEnabled = () => {
    const checkoutCartValidationEnabled = useFeatureIsOn(
      'enable-web-checkout-cart-validation'
    );
    return checkoutCartValidationEnabled;
  };

  const isNewCardFlowEnabled = () => {
    const newCardFlowEnabled = useFeatureIsOn(
      'enable-web-checkout-new-card-flow'
    );
    return newCardFlowEnabled;
  };
  const isRepurchaseFreeDeliveryEnabled = () => {
    const repurchaseFreeDeliveryEnabled = useFeatureIsOn(
      'enable-web-checkout-repurchase-free-delivery'
    );
    return repurchaseFreeDeliveryEnabled;
  };

  const MOVExperimentValue = () => {
    const experimentValue: any = useFeatureValue('enable-web-mov', 0);
    return experimentValue;
  };

  const isCashGiftingEnabled = (opsId: number) => {
    const experimentValue: any = useFeatureValue('enable-web-cash-gifting', {
      enabledOps: [],
    });
    return experimentValue?.enabledOps?.includes(opsId);
  };

  return {
    getGeoLocationEnabledOps,
    isCustomCardEnabled,
    getNewMsgsEnabledOps,
    isGiftWrapEnabled,
    isCartCheckoutValidEnabled,
    isNewCardFlowEnabled,
    isRepurchaseFreeDeliveryEnabled,
    MOVExperimentValue,
    isCashGiftingEnabled,
  };
};
