import React, { createContext, useEffect, useReducer } from 'react';
import { APP_CONFIGS } from '@flowardco/flib-helpers';
import type {
  OperationCenterModel,
  CountryModel,
} from '@flowardco/flib-models';

export interface AppContextProps {
  opsList: CountryModel[];
  layoutData: any;
  isAppLoading: boolean;
  currentOpsCountry?: CountryModel;
  lang: string;
  isArabic: boolean;
  isLocaleOnly: boolean;
  currentOpsCenter?: OperationCenterModel;
  opsUrl: string;
  opsCountryId: number;
  opsId: number;
  currencyIso: string;
  currency: string;
  globalData?: any;
  isUKDomain?: boolean;
  userGeoIpCountry?: string;
  cfIpData?: any;
  segmentLoaded?: boolean;
  segmentUserId?: string;
  activePageUrl?: string;
  hostname?: string;
  gtmLoaded?: boolean;
  brazeLoaded?: boolean;
  ssrAuthenticated?: boolean;
  ssrAuthUser?: string;
}

export interface AppStateActionProps {
  type: string;
  payload?: any;
}

export const defaultAppContext: AppContextProps = {
  opsList: [],
  layoutData: {},
  isAppLoading: true,
  currentOpsCountry: undefined,
  lang: 'en',
  isArabic: false,
  isLocaleOnly: false,
  currentOpsCenter: undefined,
  opsUrl: '/',
  opsCountryId: 1,
  opsId: 1,
  currencyIso: 'KWD',
  currency: 'KWD',
  globalData: {},
  isUKDomain: APP_CONFIGS.app.isUKDomain,
  userGeoIpCountry: '',
  cfIpData: {},
  segmentLoaded:
    (typeof window !== 'undefined' && window['analytics']) || false,
  segmentUserId: '',
  activePageUrl: '/',
  gtmLoaded:
    (typeof window !== 'undefined' &&
      window['google_tag_manager'] &&
      window['dataLayer']) ||
    false,
  brazeLoaded: (typeof window !== 'undefined' && window['braze']) || false,
  ssrAuthenticated: false,
  ssrAuthUser: '',
};

export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_OPS_LIST = 'SET_OPS_LIST';
export const SET_APP_GLOBAL_DATA = 'SET_APP_GLOBAL_DATA';
export const SET_CF_IP_DATA = 'SET_CF_IP_DATA';
export const SET_SEGMENT_LOADED = 'SET_SEGMENT_LOADED';
export const SET_SEGMENT_ANNONYMOUS_ID = 'SET_SEGMENT_ANNONYMOUS_ID';
export const SET_GTM_LOADED = 'SET_GTM_LOADED';
export const SET_BRAZE_LOADED = 'SET_BRAZE_LOADED';

export const AppContext = createContext<any>(defaultAppContext);

export const appReducer = (
  state: AppContextProps,
  action: AppStateActionProps
): any => {
  switch (action.type) {
    case SET_OPS_LIST:
      return { ...state, ...action.payload };
    case SET_APP_LOADING:
      return { ...state, isAppLoading: action.payload };
    case SET_APP_GLOBAL_DATA:
      return {
        ...state,
        globalData: {
          ...state.globalData,
          ...action.payload,
        },
      };

    case SET_CF_IP_DATA:
      return {
        ...state,
        cfIpData: action.payload,
        userGeoIpCountry: action.payload?.loc || '',
      };
    case SET_SEGMENT_LOADED:
      return { ...state, segmentLoaded: action.payload };
    case SET_SEGMENT_ANNONYMOUS_ID:
      return { ...state, segmentUserId: action.payload };
    case SET_GTM_LOADED:
      return { ...state, gtmLoaded: action.payload };
    case SET_BRAZE_LOADED:
      return { ...state, brazeLoaded: action.payload };

    default:
      return state;
  }
};

export const AppContextProvider: React.FC<{
  pathname?: string;
  hostname?: string;
  initSSRprops?: AppContextProps;
  children?: React.ReactNode;
}> = ({ initSSRprops = {}, ...props }) => {
  const { pathname, hostname } =
    typeof window !== 'undefined'
      ? window.location
      : { pathname: props?.pathname || '/', hostname: props?.hostname || '' };

  const [appState, appDispatch] = useReducer(appReducer, {
    ...defaultAppContext,
    ...initSSRprops,
  });

  const appConfigs = {
    pathname,
    hostname,
    config: APP_CONFIGS,
    appState,
    appDispatch,
  };

  const setSegmentAnonymousId = () => {
    if (appState.segmentLoaded) {
      window['analytics'].ready(() => {
        const segment_anonymous_id =
          window['analytics'].user() &&
          window['analytics'].user().anonymousId();
        appDispatch({
          type: SET_SEGMENT_ANNONYMOUS_ID,
          payload: segment_anonymous_id,
        });
      });
    }
  };

  useEffect(() => {
    setSegmentAnonymousId();
  }, [appState.segmentLoaded]);

  return (
    <AppContext.Provider value={appConfigs}>
      {props.children}
    </AppContext.Provider>
  );
};
