import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import { addToast } from '@flowardco/flib-util';
import {
  internalHttpClient,
  API_ENDPOINTS,
  API_SERVICE_URLS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';
import { setUrlSource, getUrlSource } from '@/lib/util/sessionStore';

const api2Client = internalHttpClient({ baseURL: API_SERVICE_URLS.API2 });
const kongOrderV2Client = internalHttpClient({
  baseURL: API_SERVICE_URLS.KONG_ORDERV2,
});

// Get Message and Image/Video by the sender
export const fetchRecipientMessageData = async (
  orderToken: string,
  request: any
) => {
  try {
    let source;

    if (!getUrlSource()) {
      const url = new URL(request.url);
      source = url.searchParams.get('source') || '3'; // default to hub to avoid validation issue
      if (source) {
        setUrlSource(source);
      }
    } else {
      source = getUrlSource() || '3'; // default to hub to avoid validation issue
    }

    const res = await api2Client.get(
      formatUrlParams(API_ENDPOINTS.recipientApp.getRecipientMessageInfo, {
        orderToken,
      }),
      {
        params: {
          source,
        },
      }
    );
    const recipientMessageInfo = formatHttpResponse(res);
    return recipientMessageInfo;
  } catch (error: any) {
    errorLoggerV2('RecipientMessage::fetchRecipientMessageData', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};

// Get the list of rating reasons
export const fetchRatingReasons = async (showTo: number) => {
  try {
    const res = await kongOrderV2Client.get(
      API_ENDPOINTS.orderV2.recipientApp.getRatingReasons,
      { params: { showTo } }
    );

    const reasonsInfo = formatHttpResponse(res);
    return reasonsInfo;
  } catch (error: any) {
    errorLoggerV2('Recipient:fetchRatingReasons', error);
    addToast(error?.response?.data?.message || 'Some error occurred', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occurred');
  }
};

// Set user rating
export const addRecipientRating = async (
  orderId: number,
  orderToken: string,
  data: any
) => {
  try {
    const res = await api2Client.post(
      formatUrlParams(API_ENDPOINTS.recipientApp.addRecipientRating, {
        orderId,
        orderToken,
      }),
      data
    );
    const ratingResponse = formatHttpResponse(res);
    return ratingResponse;
  } catch (error: any) {
    errorLoggerV2('Recipient:addRecipientRating', error);
    addToast(error?.response?.data?.message || 'Some error occurred', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occurred');
  }
};

// Upload Message and Image/Video for the sender
export const postRecipientMessageData = async (
  orderToken: string,
  data: any
) => {
  try {
    const res = await api2Client.post(
      formatUrlParams(API_ENDPOINTS.recipientApp.setRecipientsFeelings, {
        orderToken,
      }),
      data
    );
    const recipientPostResponse = formatHttpResponse(res);
    return recipientPostResponse;
  } catch (error: any) {
    errorLoggerV2('RecipientMessage::postRecipientMessageData', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error);
  }
};
