import { AddressIntro } from '@/components/AddressIntro';
import DeliverySlotsInfo from './DeliverySlotsInfo';
import DeliveryAddressInfo from './DeliveryAddressInfo';
import { useEffect, useState } from 'react';
import { Button } from '@flowardco/fui-button';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { getNumberOfDaysFromDate } from '@/lib/helpers/helpers';
import { addToast, convertDateToYMD } from '@flowardco/flib-util';
import { changeDeliveryDates, changeAddressData } from '@/api/location.service';
import { useLocalSegment } from '@/hooks/useLocalSegment';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';

export const AddressPageUI = ({
  orderData,
  token,
  lang,
  showCallCenter,
  setShowCallCenter,
  firstSlotInfo,
}: any) => {
  const { __T } = useLocalTranslator();
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const { trackEvents } = useLocalSegment();
  const isSlotExpired = orderData?.passedCutoffTime || false;
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [hasMapError, setHasMapError] = useState<boolean>(false);
  const [updatedSlot, setUpdatedSlot] = useState<{
    date: any;
    slot: any;
  }>({
    date: isSlotExpired ? firstSlotInfo?.[0]?.date : null,
    slot: isSlotExpired ? firstSlotInfo?.[0] : null,
  });
  const validateMap = () => {
    const isMapRequired = orderData?.isMapPinMandatory || false;
    const isMapHasError = isMapRequired && !orderData?.lat && !orderData?.lng;
    setHasMapError(isMapHasError);
  };
  const onConfirmDates = (date: any, slot: any) => {
    setUpdatedSlot({ date, slot });
  };
  const onAddressSaveSubmit = async (address: any, fromMap: boolean) => {
    setIsProcessing(true);
    const res = await changeAddressData(address);
    setIsProcessing(false);
    if (res) {
      if (fromMap) {
        trackEvents('pin added', { page_name: 'recipient_location' });
      }
      if (!fromMap) {
        trackEvents('Save Button', { page_name: 'recipient_location' });
        // if updated slots are there, then save it
        if (updatedSlot?.date && updatedSlot?.slot?.id) {
          const saveDates = await changeDeliveryDates(
            token,
            updatedSlot?.date,
            updatedSlot?.slot?.id
          );
          if (saveDates) {
            addToast(
              __T(
                `You've successfully updated the address and delivery timeslot.`
              )
            );
            const sentData = {
              date: updatedSlot?.date,
              time_slot: updatedSlot?.slot?.name,
              day: updatedSlot?.slot?.title
                ? updatedSlot?.slot?.title
                : getNumberOfDaysFromDate(
                    convertDateToYMD(new Date()),
                    updatedSlot?.date
                  ),
              is_authenticated: false,
              page_name: 'recipient_location',
            };
            trackEvents('Delivery Slot Selected', sentData);
            navigate('done');
            return;
          }
        } else if (!orderData?.passedCutoffTime) {
          addToast(res);
          navigate('done');
        }
      }
    }
    revalidator.revalidate();
    validateMap();
    return res;
  };
  useEffect(() => {
    if (hasMapError) {
      validateMap();
    }
  }, [hasMapError, orderData]);

  return (
    <AppErrorBoundary>
      <div className='fui-px-4'>
        <AppErrorBoundary>
          <div className='fui-block fui-pb-2'>
            <AddressIntro orderData={orderData} />
          </div>
        </AppErrorBoundary>
        <AppErrorBoundary>
          <DeliverySlotsInfo
            orderData={orderData}
            onConfirmDates={onConfirmDates}
            updatedSlot={updatedSlot}
            firstSlotInfo={firstSlotInfo?.[0] || {}}
          />
        </AppErrorBoundary>
        <AppErrorBoundary>
          <DeliveryAddressInfo
            orderData={orderData}
            token={token}
            lang={lang}
            callCenter={{
              showCallCenter: showCallCenter,
              setShowCallCenter: setShowCallCenter,
            }}
            formId={'addressForm'}
            onAddressSave={onAddressSaveSubmit}
            hasMapError={hasMapError}
          />
        </AppErrorBoundary>
      </div>
      <div className='fui-pt-[55px]'>
        <div className='fui-fixed fui-bottom-0 fui-z-20 fui-flex fui-w-full fui-items-center fui-bg-white fui-px-5 fui-py-3 fui-align-middle fui-shadow-stickyfooter md:fui-relative md:fui-bg-transparent md:fui-shadow-none'>
          <Button
            type='submit'
            label={__T('Save')}
            className='fui-w-full !fui-py-3 disabled:!fui-border-gray-300 disabled:!fui-bg-gray-300 disabled:fui-shadow-flwBtn'
            disabled={isProcessing}
            isProcessing={isProcessing}
            form='addressForm'
            onClick={validateMap}
          />
        </div>
      </div>
    </AppErrorBoundary>
  );
};
